<style lang="scss" scoped>
//pc端样式
@media screen and (min-width: 769px) {
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0 70px;
    line-height: 45px;
    background: #ffffff;
    height: 64px;
    color: #ffff;

    .head-left {
      cursor: pointer;
      margin-top: 15px;

      img {
        width: 246.3px;
        height: 70px;
      }
    }

    .head-right {
      background-color: #e49c17;
      width: 100px;
      line-height: 35px;
      font-size: 12px;
      color: black;
      text-align: center;
      cursor: pointer;
      border-radius: 35px;
    }
  }

  .middle {
    width: 100%;
    background: #deeff3;
    height: 655px;
    overflow: hidden;

    .content {
      width: 800px;
      height: 400px;
      background-color: #fff;
      margin: auto;
      margin-top: 80px;
      margin: auto;

      .content-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 50px;
        padding: 0 30px;
        margin-top: 118px;

        .head-login {
          color: #0f333a;
          font-size: 20px;
          font-weight: 400;
        }

        .head-live {
          background-color: #1097b3;
          width: 80px;
          text-align: center;
          line-height: 35px;
          color: #fff;
          font-size: 12px;
          border-radius: 3px;
        }
      }

      .content-mid {
        display: flex;
        background-color: #0f0e0e;

        .mid-icon {
          width: 60px;
          line-height: 60px;
          background-color: #d1bd06;
        }

        .mid-text {
          color: #fff;
          line-height: 60px;
          margin-left: 20px;
        }
      }

      .mid-login {
        padding: 30px;
        border-bottom: 1px solid #0f0e0e;

        .demo-ruleForm {
          margin-left: 100px;
        }

        .login-input {
          width: 400px;
          margin-left: 50px;
        }
      }

      .login_button {
        display: flex;
        width: 50%;
        margin: auto;
        align-items: center;
      }

      .loginW {
        width: 100px;
        margin: auto;
      }

      .forget {
        text-align: center;
        color: #02758a;
        cursor: pointer;
      }

      .mid-footer {
        margin: 20px;
        font-size: 12px;

        .foot1 {
          text-align: center;
          color: #c0b5b5;
        }

        .foot2 {
          text-align: center;
          color: #1097b3;
          margin-top: 5px;
          cursor: pointer;
        }
      }
    }
  }
}

//移动端样式
@media screen and (max-width: 768px) {
  .head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    background: #ffffff;
    color: #ffff;
    padding-left: 3%;
    padding-right: 3%;
    height: 67.5px;

    .head-left {
      cursor: pointer;

      img {
        width: 158.34;
        height: 45px;
      }
    }

    .head-right {
      background-color: #e49c17;
      width: 100px;
      line-height: 35px;
      font-size: 18px;
      color: black;
      text-align: center;
      cursor: pointer;
      border-radius: 35px;
    }
  }

  .middle {
    width: 100%;
    background: linear-gradient(180deg, lightblue, #fff, #fff) !important;
    overflow: hidden;

    .content {
      min-height: 700px;
      height: 100%;
      width: 90%;

      margin: auto;

      .content-head {
        display: flex;
        justify-content: space-between;
        align-items: center;
        line-height: 50px;
        height: 60px;
        padding-left: 20px;
        border-bottom: 1px solid #02758a;

        .head-login {
          color: #0f333a;
          font-size: 20px;
          font-weight: 400;
        }

        .head-live {
          background-color: #1097b3;
          width: 80px;
          text-align: center;
          line-height: 35px;
          color: #fff;
          font-size: 12px;
          border-radius: 3px;
        }
      }

      .content-mid {
        display: none;
        background-color: #0f0e0e;

        .mid-icon {
          width: 60px;
          line-height: 60px;
          background-color: #d1bd06;
        }

        .mid-text {
          color: #fff;
          line-height: 60px;
          margin-left: 20px;
        }
      }

      .mid-login {
        margin-top: 60px;
        padding: 30px;
        width: 90%;

        .login-input {
          width: 80%;
        }
      }

      .loginW {
        margin: auto;
        text-align: center;
        margin-bottom: 10px;
      }

      .forget {
        margin-top: 20px;
        margin: auto;
        text-align: center;
        display: block;
        color: #02758a;
        cursor: pointer;
      }

      .mid-footer {
        height: 120px;
        border-top: 1px solid #0f0e0e;
        width: 100%;
        font-size: 12px;
        padding-top: 20px;
        padding-bottom: 20px;

        .foot1 {
          text-align: center;
          color: #c0b5b5;
          font-size: 16px;
        }

        .foot2 {
          text-align: center;
          color: #1097b3;
          margin-top: 8px;
          cursor: pointer;
          font-size: 16px;
        }
      }
    }
  }
}
</style>

<template>
  <div>
    <div class="head">
      <div class="head-left" @click="goOnlineExpert">
        <img src="../../assets/mainLogo.png" alt="" />
      </div>
      <p class="head-right" @click="goPageReg">join us!</p>
    </div>
    <div class="middle">
      <div class="content">
        <div class="content-head">
          <div class="head-login">ZESTWISDOM Expert Login</div>
        </div>
        <div class="content-mid">
          <p class="mid-icon"></p>
          <p class="mid-text">Please log in to access the requested page</p>
        </div>
        <div class="mid-login">
          <el-form
            ref="loginFromRef"
            :model="loginFnForm"
            status-icon
            :rules="loginRules"
            label-width="100px"
            class="demo-ruleForm"
          >
            <el-form-item label="Email：" prop="email" class="login-input">
              <el-input v-model="loginFnForm.email" type="email" />
            </el-form-item>
            <el-form-item label="Password：" prop="password" class="login-input">
              <el-input v-model="loginFnForm.password" type="password" show-password />
            </el-form-item>
          </el-form>
          <div class="login_button">
            <div class="loginW">
              <el-button
                type="primary"
                :loading-icon="Eleme"
                :loading="createLoading"
                :disabled="LoginDisable"
                @click="loginFn"
                >Login</el-button
              >
            </div>

            <div class="forget" @click="goPageForgot">Forgot your password?</div>
          </div>
        </div>
        <div class="mid-footer">
          <p class="foot1">Don't have an account?</p>
          <p class="foot2" @click="goPageReg">Join us now!</p>
        </div>
      </div>
    </div>
    <div class="footer">
      <Footer></Footer>
    </div>
  </div>
</template>
<script setup>
import Footer from "../.././layout/components/Footer.vue";
import { reactive, ref, onMounted } from "vue";
import { useRouter } from "vue-router";
import { conLogin } from "@/api/sys.js";
import { ElMessage } from "element-plus";
import { setToken, setType, setConlant, getType, getToken } from "@/utils/auth";
import request from "@/utils/request.js";
import { useStore } from "vuex";
const store = useStore();
const router = useRouter();
//邮箱验证
const checkEmail = (rule, value, callback) => {
  const mailReg = /^[a-zA-Z0-9_.-]+(_?@[a-zA-Z0-9-]+\.[a-zA-Z0-9-.]+)$/;
  if (!value) {
    return callback(new Error("Email cannot be empty"));
  }
  setTimeout(() => {
    if (mailReg.test(value)) {
      callback();
    } else {
      callback(new Error("Please enter the correct email format"));
    }
  }, 100);
};
//密码验证
const validatePass = (rule, value, callback) => {
  const pwdReg = /^(?=.*\d)(?=.*[a-zA-Z]).{6,}$/;
  if (!value) {
    return callback(new Error("Please enter the password"));
  } else if (pwdReg.test(value)) {
    callback();
  } else {
    callback(new Error("Please enter no less than 6 characters or numbers"));
  }
};

const loginRules = ref({
  email: [{ required: true, validator: checkEmail, trigger: "blur" }],
  password: [{ required: true, validator: validatePass, trigger: "blur" }],
});
onMounted(() => {
  window.scrollTo(0, 0);
});
const createLoading = ref(false);
const LoginDisable = ref(false);
const goPageReg = () => {
  router.push("/pageReg");
};
const goPageForgot = () => {
  router.push("/forgotPass");
};
const loginFnForm = ref({
  email: "",
  password: "",
});
//顾问登录
const loginFromRef = ref(null);
const loginFn = () => {
  loginFromRef.value.validate(async (valid) => {
    if (valid) {
      createLoading.value = true;
      LoginDisable.value = true;
      const res = await conLogin(loginFnForm.value);
      if (res.code == 200) {
        setToken(res.data.access_token);
        window.localStorage.token = res.data.access_token;
        setType("1");
        createLoading.value = false;
        LoginDisable.value = false;
        getLoginConsuInfo();
        // router.push('/profileInfo')
      } else {
        createLoading.value = false;
        LoginDisable.value = false;
        ElMessage.error({
          grouping: true,
          message: res.msg,
        });
      }
    } else {
      console.log("else");
      ElMessage.error({
        grouping: true,
        message: "Incorrect account or password format",
        center: false,
      });
      return false;
    }
  });
};
const goOnlineExpert = () => {
  router.push("/onlineExpert");
};
//Your information is not complete, please fill in your information first！
// 获取顾问信息
async function getLoginConsuInfo() {
  const { code, data } = await request.get("/system/faConsultant/getLoginConsuInfo");
  if (code == 200) {
    console.log(data, "data");
    console.log(data.auditStatus, "data.auditStatus");
    // 判断顾问的当前注册状态 0=注册邮箱未认证,1=注册邮箱已认证提交资料审核,2=资料审核通过,3=资料审核驳回
    if (data.auditStatus == "0") {
      ElMessage({
        message:
          "Your mailbox has not been authenticated, please authenticate your mailbox!",
        type: "warning",
      });
      store.commit("setRegisterActive", 2);
      router.push("/pageReg");
    } else if (data.auditStatus == "1") {
      //注册邮箱已认证
      ElMessage({
        message:
          "Your information is not complete, please fill in your information first!",
        type: "warning",
      });
      store.commit("setRegisterActive", 6);
      router.push("/pageReg");
    } else if (data.auditStatus == "4") {
      //流程5
      ElMessage({
        message:
          "Your information is not complete, please fill in your information first!",
        type: "warning",
      });
      store.commit("setRegisterActive", 5);
      router.push("/pageReg");
    } else if (data.auditStatus == "5") {
      //流程6
      ElMessage({ message: "Please be patient during the review!", type: "warning" });
      store.commit("setRegisterActive", 6);
      router.push("/pageReg");
    } else if (data.auditStatus == "3") {
      //驳回
      ElMessage({
        message:
          "Your application for registration has been rejected.  Please resubmit it for review!",
        type: "warning",
      });
      store.commit("setRegisterActive", 3);
      router.push("/pageReg");
    } else if (data.auditStatus == "2") {
      setConlant("2");
      router.push("/profileInfo");
    }

    // 储存用户信息
    store.commit("setUserInfo", JSON.stringify(data));
  }
}
</script>
